import React from "react";
import {
  File,
  Home,
  Percent,
  PlusSquare,
  ShoppingCart,
  User,
  Users,
} from "react-feather";
import { AiOutlineBank } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";

import { RiLockPasswordLine } from "react-icons/ri";
import { TiMessages } from "react-icons/ti";
import async from "../components/Async";
import { FaTasks } from "react-icons/fa";
import DadosBancarios from "../pages/cadastro/contrato/DadosBancarios";
import {
  AccountBalance,
  AttachFile,
  Business,
  LocationCity,
  Person,
  Person2,
  Person3,
  Person4,
} from "@mui/icons-material";
const PesquisaContratoVisualizacao = async(() =>
  import("../pages/cadastro/contrato/PesquisaContratoVisualizacao")
);

const PesquisaContrato = async(() =>
  import("../pages/cadastro/contrato/PesquisaContrato")
);
const PesquisarAdministrativo = async(() =>
  import("../pages/pessoa/PesquisarAdministrativo")
);
const ManterAdministrativo = async(() =>
  import("../pages/pessoa/ManterAdministrativo")
);
const TokenMFA = async(() => import("../pages/auth/TokenMFA"));
const PesquisarEscritorio = async(() =>
  import("../pages/pessoa/PesquisarEscritorioAssessor")
);
const PesquisarCliente = async(() =>
  import("../pages/cliente/PesquisarCliente")
);
const ManterEscritorio = async(() =>
  import("../pages/pessoa/ManterEscritorio")
);
const ManterCliente = async(() => import("../pages/cliente/ManterCliente"));
const ResetPasswordInputSenha = async(() =>
  import("../pages/auth/ResetPasswordInputSenha")
);
const Empresas = async(() => import("../pages/empresa/Empresas"));
const Tasks = async(() => import("../pages/tarefas/Tasks"));

const AlterarSenha = async(() =>
  import("../pages/cadastro/alterarsenha/AlterarSenha")
);
const Notificacao = async(() =>
  import("../pages/cadastro/notificacao/Notificacao")
);
const Empresa = async(() => import("../pages/cadastro/empresa/Empresa"));
const Banco = async(() => import("../pages/cadastro/banco/Banco"));
const Taxa = async(() => import("../pages/cadastro/classe/Taxa"));
const EmpresaGrupo = async(() =>
  import("../pages/cadastro/empresagrupo/EmpresaGrupo")
);

const Perfis = async(() => import("../pages/cadastro/perfis/Perfis"));
const AuthGuard = async(() => import("../components/AuthGuard"));

const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const SignInCadastro = async(() => import("../pages/auth/SignInCadastro"));

const Settings = async(() => import("../pages/manutencao/Settings"));
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const empresasRoutes = {
  id: "Empresas",
  path: "/empresas",
  perfil: ["SUPORTE", "FINANCEIRO"],
  icon: <AiOutlineBank />,
  permission: [],
  component: Empresas,
  children: null,
  guard: AuthGuard,
};

const consultasRoutes = {
  id: "Cadastros",
  path: "/cadastros",
  permission: [
    "manutencao_perfis",
    "manter_pessoa",
    "manter_banco",
    "manter_classe",
    "manter_cliente",
    "manter_status_contrato",
    "manter_contrato",
    "manter_escritorio_assessor",
  ],
  icon: <PlusSquare />,
  guard: AuthGuard,
  children: [
    {
      path: "/escritorio/cadastro/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ["manter_escritorio_assessor"],
      component: ManterEscritorio,
      guard: AuthGuard,
    },
    {
      name: "Contrato",
      path: "/contrato/cadastro/",
      permission: ["manter_contrato"],
      component: PesquisaContrato,
      guard: AuthGuard,
    },
    {
      name: "Contrato",
      path: "/contrato/cadastro/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ["manter_contrato"],
      component: DadosBancarios,
      guard: AuthGuard,
    },
    {
      name: "Visualizar Contrato",
      path: "/contrato/visualizar/",
      hiddenSidebar: true,
      permission: ["apenas_visualizar_contratos"],
      component: PesquisaContratoVisualizacao,
      guard: AuthGuard,
    },
    {
      path: "/administrativo/cadastro/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ["manter_pessoa"],
      component: ManterAdministrativo,
      guard: AuthGuard,
    },
    {
      path: "/escritorio/cadastro",
      hiddenSidebar: true,
      permission: ["manter_escritorio_assessor"],
      component: ManterEscritorio,
      guard: AuthGuard,
    },
    {
      path: "/administrativo/cadastro",
      hiddenSidebar: true,
      permission: ["manter_pessoa"],
      component: ManterAdministrativo,
      guard: AuthGuard,
    },
    {
      path: "/cliente/cadastro/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ["manter_cliente"],
      component: ManterCliente,
      guard: AuthGuard,
    },
    {
      path: "/cliente/cadastro",
      hiddenSidebar: true,
      permission: ["manter_cliente"],
      component: ManterCliente,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/banco",
      name: "Banco",
      permission: ["manter_banco"],
      component: Banco,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/taxa",
      name: "Taxa",
      permission: ["manter_classe"],
      component: Taxa,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/cliente",
      name: "Cliente",
      permission: ["manter_cliente"],
      component: PesquisarCliente,
      guard: AuthGuard,
    },
    {
      name: "Escritorio/Assessor",
      path: "/escritorio/pesquisa",
      permission: ["manter_escritorio_assessor"],
      component: PesquisarEscritorio,
      guard: AuthGuard,
    },
    {
      name: "Administrativo",
      path: "/administrativo/pesquisa",
      permission: ["manter_pessoa"],
      component: PesquisarAdministrativo,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/empresa",
      name: "Empresa",
      permission: ["manter_empresa_grupo"],
      component: EmpresaGrupo,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/perfis",
      name: "Perfis",
      permission: ["manutencao_perfis"],
      component: Perfis,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const empresaRoute = {
  id: "Empresa",
  path: "/empresa",
  permission: [],
  icon: <ShoppingCart />,
  component: Empresa,
  children: null,
  guard: AuthGuard,
};

const authRoutes = {
  id: "Auth",
  path: "/",
  permission: [],
  icon: <Users />,
  children: [
    {
      path: "/",
      name: "Login",
      component: SignIn,
    },
    {
      path: "/auth/posCadastro/:token",
      hiddenSidebar: true,
      component: SignInCadastro,
    },
    {
      path: "/auth/reset-password",
      name: "Esqueci Minha Senha",
      component: ResetPassword,
    },
    {
      path: "/recuperarSenha/:hash",
      name: "Alterar Senha",
      component: ResetPasswordInputSenha,
    },
    {
      path: "/auth/mfa",
      name: "Token MFA",
      component: TokenMFA,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const componentsRoutes = {
  path: "/",
  header: "Produtividade",
  permission: [],
  children: [],
  component: null,
  guard: AuthGuard,
};

const tarefasRoutes = {
  id: "Tarefas",
  path: "/tarefas",
  icon: <FaTasks />,
  permission: [],
  component: Tasks,
  children: null,
  guard: AuthGuard,
};

const notificacaoRoutes = {
  id: "Notificações",
  path: "/notificacao/manter",
  permission: [],
  icon: <TiMessages />,
  component: Notificacao,
  children: null,
  guard: AuthGuard,
};

const meuPontoRoutes = {
  path: "/",
  header: "Minha Área",
  permission: [],
  children: [],
  component: null,
  guard: AuthGuard,
};

const alterarSenha = {
  id: "Alterar Senha",
  permission: [],
  path: "/alterarSenha",
  icon: <RiLockPasswordLine />,
  component: AlterarSenha,
  children: null,
  guard: AuthGuard,
};

const meuPerfil = {
  id: "Meus Dados",
  permission: [],
  path: "/manutencao/profile",
  icon: <CgProfile />,
  component: Settings,
  children: null,
  guard: AuthGuard,
};

const Contrato = {
  id: "Contratos",
  path: "/contrato/cadastro/",
  permission: ["manter_contrato"],
  component: PesquisaContrato,
  guard: AuthGuard,
  icon: <AttachFile></AttachFile>,
  children: null,
};

const Escritorio = {
  id: "Escritorios/Assessores",
  path: "/escritorio/pesquisa/",
  permission: ["manter_escritorio_assessor"],
  component: PesquisarEscritorio,
  guard: AuthGuard,
  icon: <Business></Business>,
  children: null,
};

const Administrativo = {
  id: "Administrativo",
  path: "/administrativo/pesquisa",
  permission: ["manter_pessoa"],
  component: PesquisarAdministrativo,
  guard: AuthGuard,
  icon: <Person3></Person3>,
  children: null,
};

const Cliente = {
  id: "Clientes",
  path: "/cadastros/cliente",
  permission: ["manter_cliente"],
  component: PesquisarCliente,
  guard: AuthGuard,
  icon: <Person2></Person2>,
  children: null,
};

const BancoRoute = {
  id: "Banco",
  path: "/cadastros/banco",
  permission: ["manter_banco"],
  component: ManterCliente,
  guard: AuthGuard,
  icon: <AccountBalance></AccountBalance>,
  children: null,
};

const TaxaRoute = {
  id: "Taxas",
  path: "/cadastros/taxa",
  permission: ["manter_classe"],
  component: Taxa,
  guard: AuthGuard,
  icon: <Percent></Percent>,
  children: null,
};

const EmpresaRoute = {
  id: "Empresa",
  path: "/cadastros/empresa",
  permission: ["manter_empresa_grupo"],
  component: EmpresaGrupo,
  guard: AuthGuard,
  icon: <LocationCity></LocationCity>,
  children: null,
};

const PerfisRoute = {
  id: "Perfis",
  path: "/cadastros/perfis",
  permission: ["manutencao_perfis"],
  component: Perfis,
  guard: AuthGuard,
  icon: <Person4></Person4>,
  children: null,
};

const ContratoVisualizacao = {
  id: "Visualizar contratos",
  path: "/contrato/visualizar/",
  permission: ["apenas_visualizar_contratos"],
  component: PesquisaContratoVisualizacao,
  guard: AuthGuard,
  icon: <AttachFile></AttachFile>,
  children: null,
};

const protectedPageRoutes = {
  id: "Private",
  permission: [],
  path: "/app",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  consultasRoutes,
  empresasRoutes,
  empresaRoute,
  componentsRoutes,
  notificacaoRoutes,
  tarefasRoutes,
  meuPontoRoutes,
  meuPerfil,
  alterarSenha,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  Contrato,
  ContratoVisualizacao,
  Escritorio,
  Administrativo,
  Cliente,
  BancoRoute,
  TaxaRoute,
  EmpresaRoute,
  PerfisRoute,
  meuPerfil,
  alterarSenha,
];
